import { useEffect } from 'react';
import './RequirementsApprovalChangeThankYouPage.css';

const RequirementsApprovalChangeThankYouPage = () => {
	useEffect(() => {
		document.body.classList.add('gray-background');
	}, []);

	return (
		<div className="container-fluid requirements-approval-change-container">
			<div className="container">
				<div className="requirements-approval-change-container-inner">
					<div className="row">
						<div className="col-12 title-wrapper">
							<h2 className="title text-center">Change Request Submitted</h2>
						</div>

						<div className="col-12 mt-0 mb-4">
							<hr className="mt-0" />
						</div>

						<div className="col-12 mb-2">
							<p className="mb-3">Thanks for submitting your change request details.</p>
							<p className="my-3">
								We will review your request and resend this approval request with the updated details
								for you to review and accept.
							</p>
							<p className="mb-3">
								If you have any immediate questions you can always visit our support site and start a
								live chat or submit a support ticket at:
							</p>
							<p className="mb-3">
								<a href="https://support.realwebsite.com" target="_blank">
									https://support.realwebsite.com
								</a>
							</p>
							<p className="mb-3">
								Or send an email to{' '}
								<a href="mailto:mapboost@realwebsite.com">mapboost@realwebsite.com</a> and someone from
								our Map Boost fulfillment team will get back to you ASAP.
							</p>
							<p className="mb-3">Best Regards</p>
							<p className="mb-0">
								<strong>John Schroeder</strong>
							</p>
							<p className="mb-3">Co-Creator of RealWebsite</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RequirementsApprovalChangeThankYouPage;
