import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	GenerateSEOReportDataGrid,
	GenerateSEOReportPreview,
	GetCampaign,
	GetCampaignLocations,
	UpdateCampaign,
	UpdateCampaignLocations,
	UpdateCampaignStatus
} from '../../../Services';
import Spinner from '../../../components/spinner/Spinner';
import './CampaignLocationsScreen.css';
import { toast } from 'react-toastify';
import CampaignTabs from '../../../components/campaignTabs/CampaignTabs';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';
import ModalDialog from '../../../components/modalDialog/ModalDialog';
import ModalDialogContent from '../../../components/modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../components/modalDialog/modalDialogButtons/ModalDialogButtons';
import GridReportDataGridV2 from '../../../tools/dataGridV2/GridReportDataGridV2';
import CampaignButtonsContainer from '../campaignButtonsContainer/CampaignButtonsContainer';
import { copyToClipboard } from '../../../utils/Common';

const CampaignLocationsScreen = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ formData, setFormData ] = useState({});
	const [ locations, setLocations ] = useState();
	const [ stateName, setStateName ] = useState('');
	const [ stateAbbr, setStateAbbr ] = useState('');
	const [ showAppendStateDialog, setShowAppendStateDialog ] = useState(false);
	const [ showGridToolDialog, setShowGridToolDialog ] = useState(false);
	const [ radius, setRadius ] = useState('10');
	const [ lat, setLat ] = useState();
	const [ lng, setLng ] = useState();
	const [ distance, setDistance ] = useState('');
	const [ selectedReport, setSelectedReport ] = useState();
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const [ showCopiedMessage, setShowCopiedMessage ] = useState(false);

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCampaignLocations();
			}
		},
		[ campaignId ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			document.title = response.name;
			setCampaign(response);
			setFormData(response);

			setLat(response.lat);
			setLng(response.lng);
		}
	};

	const getCampaignLocations = async () => {
		setIsLoading(true);

		const response = await GetCampaignLocations(campaignId);

		if (response) {
			let locationsArray = response;
			let tempLocations = '';

			for (let i = 0; i < locationsArray.length; i++) {
				if (locationsArray[i].location && locationsArray[i].location.trim() !== '') {
					tempLocations +=
						locationsArray[i].location +
						', ' +
						locationsArray[i].state +
						', ' +
						locationsArray[i].stateAbbr +
						'\n';
				}
			}

			setLocations(tempLocations);
		}

		setIsLoading(false);
	};

	const handleChange = async (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});

		if (e.target.name === 'lat') {
			setLat(e.target.value);
		} else if (e.target.name === 'lng') {
			setLng(e.target.value);
		}
	};

	const handleSubmit = async (redirect = false) => {
		//e.preventDefault();

		setIsSubmitting(true);

		//update campaign data
		const responseCampaign = await UpdateCampaign(campaignId, formData);

		if (!responseCampaign.success) {
			console.log(responseCampaign.message);
		}

		//update campaign locations
		if (!locations || locations.trim() === '') {
			toast.error('Please add locations first');
			return;
		}

		let data = {
			id        : campaignId,
			locations : []
		};

		let locationsArray = locations.split('\n');

		if (locationsArray) {
			locationsArray.map((location) => {
				if (location && location.trim() !== '' && location.trim().replace('\n', '') !== '') {
					let locationArgs = location.split(',');

					if (locationArgs && locationArgs.length === 3) {
						data.locations.push({
							name      : locationArgs[0].trim(),
							state     : locationArgs[1].trim(),
							stateAbbr : locationArgs[2].trim()
						});
					}
				}
			});
		}

		const response = await UpdateCampaignLocations(campaignId, data);

		if (response.success) {
			if (redirect) {
				navigate(`/setup/${campaignId}/landing-pages`);
			} else {
				toast.success('Settings saved.');
			}
		}

		setIsSubmitting(false);
	};

	const handleAppendState = () => {
		if (locations) {
			let tempLocations = '';
			let locationsArray = locations.split('\n');

			if (locationsArray) {
				locationsArray.map((location) => {
					if (location && location.trim() !== '') {
						let newLocation = `${location}, ${stateName}, ${stateAbbr}`;

						tempLocations += newLocation + '\n';
					}
				});

				setLocations(tempLocations);
			}
		}

		toggleAppendStateDialog();
	};

	const toggleAppendStateDialog = async () => {
		setShowAppendStateDialog(!showAppendStateDialog);
	};

	const toggleGridToolDialog = async () => {
		setShowGridToolDialog(!showGridToolDialog);

		if (!showGridToolDialog) {
			handleGenerateGrid();
		}
	};

	const handleGenerateGrid = async () => {
		setIsGenerating(true);

		let data = {
			lat                    : parseFloat(formData.lat),
			lng                    : parseFloat(formData.lng),
			gridPoints             : formData.gridPoints,
			mapCircleRadiusInMiles : radius
		};

		const response = await GenerateSEOReportPreview(data);

		if (response.success) {
			setSelectedReport(response);

			let pointDistance = radius / ((formData.gridPoints - 1) / 2);
			setDistance(pointDistance.toFixed(2));

			setFormData({
				...formData,
				gridPointsDistanceInMiles : pointDistance.toFixed(2)
			});
		} else {
			console.error(response.message);
		}

		setIsGenerating(false);
	};

	const handleSaveDataGridDetails = async () => {
		//update campaign data
		const responseCampaign = await UpdateCampaign(campaignId, formData);

		if (responseCampaign.success) {
			toast.success('Settings saved.');
		} else {
			console.log(responseCampaign.message);
		}
	};

	const handleCircleMove = (center) => {
		if (center) {
			setLat(center.lat);
			setLng(center.lng);

			setFormData({
				...formData,
				lat : center.lat,
				lng : center.lng
			});
		}
	};

	const copyLatLng = () => {
		copyToClipboard(`${formData.lat},${formData.lng}`);
		setShowCopiedMessage(true);

		setTimeout(function() {
			setShowCopiedMessage(false);
		}, 1000);
	};

	return (
		<Fragment>
			<div className="container-fluid campaign-main-container">
				<PageHeadline
					campaign={campaign}
					headline={`Campaign - ${campaign ? campaign.name : ''}`}
					linkText="Back To List"
					linkUrl="/campaigns/list"
				/>

				<CampaignTabs />

				{isLoading ? (
					<Spinner />
				) : (
					<Fragment>
						<div className="campaign-form-container">
							<div className="row mb-3">
								<label htmlFor="lat" className="form-label col-1">
									Center Point:
								</label>
								<div className="col-5">
									<div className="input-group">
										<input
											type="text"
											className="form-control"
											name="lat"
											value={formData.lat || ''}
											onChange={handleChange}
											required
											disabled={isSubmitting}
										/>
										<input
											type="text"
											className="form-control ms-2"
											name="lng"
											value={formData.lng || ''}
											onChange={handleChange}
											required
											disabled={isSubmitting}
										/>
									</div>
								</div>
								<div className="col-1 ps-0">
									<div className="input-group align-items-center">
										<button className="btn btn-link px-0 rounded" title="Copy" onClick={copyLatLng}>
											<span>
												<i className="fa-regular fa-copy" />
											</span>
										</button>
										{showCopiedMessage && (
											<button className="btn btn-outline-success rounded py-0 px-2 ms-3">
												Copied!
											</button>
										)}
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="gridPoints" className="form-label col-1">
									Grid Points:
								</label>
								<div className="col-5">
									<input
										type="number"
										className="form-control"
										name="gridPoints"
										value={formData.gridPoints || ''}
										onChange={handleChange}
										min={1}
										max={25}
										step={1}
										disabled={isSubmitting}
									/>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="gridPointsDistanceInMiles" className="form-label col-1">
									Point Distance:
								</label>
								<div className="col-5">
									<input
										type="number"
										className="form-control"
										name="gridPointsDistanceInMiles"
										value={formData.gridPointsDistanceInMiles || ''}
										onChange={handleChange}
										min={0.1}
										max={5}
										step={0.1}
										disabled={isSubmitting}
									/>
								</div>
							</div>
							<div className="row mb-3">
								<label htmlFor="locations" className="form-label col-1">
									Locations:
								</label>
								<div className="col-5">
									<textarea
										className="form-control"
										name="locations"
										value={locations || ''}
										onChange={(e) => setLocations(e.target.value)}
										rows={10}
										disabled={isSubmitting}
									/>
									<div className="form-controls-additional">
										<button
											className="btn btn-link p-0"
											onClick={toggleAppendStateDialog}
											disabled={isSubmitting}
										>
											<span>Append State</span>
										</button>
										<span className="link-separator mx-1">|</span>
										<button
											className="btn btn-link p-0"
											onClick={toggleGridToolDialog}
											disabled={isSubmitting}
										>
											<span>Grid Tool</span>
										</button>
										<span className="link-separator mx-1">|</span>
										<a
											className="btn btn-link p-0"
											target="_blank"
											href="https://www.freemaptools.com/find-cities-and-towns-inside-radius.htm"
											disabled={isSubmitting}
										>
											<span>Get Locations</span>
										</a>
									</div>
								</div>
							</div>
						</div>

						<CampaignButtonsContainer
							campaign={campaign}
							onSave={handleSubmit}
							onSaveNext={() => handleSubmit(true)}
							disabled={isSubmitting}
							saveButtonText={`${isSubmitting ? 'Saving...' : 'Save'}`}
							saveNextButtonText={`${isSubmitting ? 'Saving...' : 'Save & Next'}`}
						/>

						{showAppendStateDialog && (
							<ModalDialog position="right-sidebar">
								<ModalDialogContent align="start">
									<form className="row">
										<div className="col-12 mb-3">
											<label htmlFor="stateName" className="form-label">
												State:
											</label>
											<input
												type="text"
												className="form-control"
												name="stateName"
												value={stateName || ''}
												onChange={(e) => setStateName(e.target.value)}
											/>
										</div>
										<div className="col-12 mb-3">
											<label htmlFor="stateAbbr" className="form-label">
												State Abbreviation:
											</label>
											<input
												type="text"
												className="form-control"
												name="stateAbbr"
												value={stateAbbr || ''}
												onChange={(e) => setStateAbbr(e.target.value)}
											/>
										</div>
									</form>
								</ModalDialogContent>
								<ModalDialogButtons>
									<button className="btn btn-primary" onClick={handleAppendState}>
										<span>Append</span>
									</button>
									<button className="btn btn-outline-primary" onClick={toggleAppendStateDialog}>
										<span>Cancel</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						)}

						{showGridToolDialog && (
							<ModalDialog position="right-sidebar large">
								<ModalDialogContent align="start">
									<form className="row mb-2">
										<div className="col-3">
											<label htmlFor="radius" className="form-label">
												Radius Miles:
											</label>
											<input
												type="number"
												className="form-control"
												id="radius"
												value={radius}
												onChange={(e) => setRadius(e.target.value)}
												min={1}
												max={100}
												step={1}
											/>
										</div>
										<div className="col-3">
											<label htmlFor="points" className="form-label">
												Grid Points:
											</label>
											<input
												type="number"
												className="form-control"
												id="points"
												name="gridPoints"
												value={formData.gridPoints || ''}
												onChange={handleChange}
												min={1}
												max={25}
												step={1}
											/>
										</div>
										<div className="col-2 d-flex  align-items-end">
											{/* <div className="col-2 d-flex align-items-end justify-content-end"> */}
											<button
												type="button"
												className="btn btn-primary"
												onClick={handleGenerateGrid}
											>
												Update
											</button>
										</div>
									</form>
									<div className="row mb-3">
										<div className="col-12">
											<div>
												{lat &&
												lng && (
													<Fragment>
														<span className="point-distance">
															Center Point: {lat}, {lng}
														</span>
														<span className="point-distance mx-3">|</span>
													</Fragment>
												)}
												{distance && (
													<Fragment>
														<span className="point-distance">
															Point distance (miles): {distance}
														</span>
													</Fragment>
												)}
											</div>
										</div>
									</div>
									<div className="row">
										{isGenerating ? (
											<Spinner />
										) : (
											<Fragment>
												<div className="map-preview">
													{campaign &&
													selectedReport && (
														<GridReportDataGridV2
															gridData={selectedReport.gridResponse}
															lat={lat}
															lng={lng}
															radiusInMiles={radius}
															numberOfPoints={formData.gridPoints}
															pointDistance={formData.gridPointsDistanceInMiles}
															googlePlaceId={campaign.googlePlaceId}
															zoom={11}
															onCircleMove={handleCircleMove}
															isPreview={true}
														/>
													)}
												</div>
											</Fragment>
										)}
									</div>
								</ModalDialogContent>
								<ModalDialogButtons>
									<button className="btn btn-primary" onClick={handleSaveDataGridDetails}>
										<span>Save</span>
									</button>
									<button className="btn btn-outline-primary" onClick={toggleGridToolDialog}>
										<span>Cancel</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default CampaignLocationsScreen;
