import { Route, Routes } from 'react-router';
import GbpSelectScreen from './../../screens/gbpSelectScreen/GbpSelectScreen';
import ConfirmBusinessSelectionScreen from './../../screens/confirmBusinessSelectionScreen/ConfirmBusinessSelectionScreen';
import ReportSettingsScreen from './../../screens/reportSettingsScreen/ReportSettingsScreen';
import CampaignReportScreen from './../../screens/campaignReportScreen/CampaignReportScreen';
import DataGrid from './../../tools/dataGrid/DataGrid';
import GbpSetupScreen from './../../screens/gbpSetupScreen/GbpSetupScreen';
import CampaignUpdateScreen from './../../screens/gbpSetupScreen/campaignUpdateScreen/CampaignUpdateScreen';
import CampaignKeywordsScreen from './../../screens/gbpSetupScreen/campaignKeywordsScreen/CampaignKeywordsScreen';
import 'react-toastify/dist/ReactToastify.css';
import CampaignLocationsScreen from './../../screens/gbpSetupScreen/campaignLocationsScreen/CampaignLocationsScreen';
import CampaignServicesScreen from './../../screens/gbpSetupScreen/campaignServicesScreen/CampaignServicesScreen';
import CampaignProductsScreen from './../../screens/gbpSetupScreen/campaignProductsScreen/CampaignProductsScreen';
import CampaignReviewsScreen from './../../screens/gbpSetupScreen/campaignReviewsScreen/CampaignReviewsScreen';
import Geocode from './../../tools/geocode/Geocode';
import CampaignFilesScreen from './../../screens/gbpSetupScreen/campaignFilesScreen/CampaignFilesScreen';
import CampaignsList from '../../screens/gbpSetupScreen/campaignsList/CampaignsList';
import DistanceFinder from '../../tools/distanceFinder/DistanceFinder';
import Sales from '../../tools/sales/Sales';
import CampaignContentScreen from '../../screens/gbpSetupScreen/campaignContentScreen/CampaignContentScreen';
import DataGridV2 from '../../tools/dataGridV2/DataGridV2';
import CampaignSocialScreen from '../../screens/gbpSetupScreen/campaignSocialScreen/CampaignSocialScreen';
import CampaignDirectoryScreen from '../../screens/gbpSetupScreen/campaignDirectoryScreen/CampaignDirectoryScreen';
import CampaignHeadlineDescScreen from '../../screens/gbpSetupScreen/campaignHeadlineDescScreen/CampaignHeadlineDescScreen';
import CampaignDesignScreen from '../../screens/gbpSetupScreen/campaignDesignScreen/CampaignDesignScreen';
import CampaignCtaScreen from '../../screens/gbpSetupScreen/campaignCtaScreen/CampaignCtaScreen';
import Administration from '../administration/Administration';
import { useStateValue } from '../../StateProvider';
import { Fragment } from 'react';
import Login from '../login/Login';
import CampaignsList_NEW from '../../screens/gbpSetupScreen/campaignsList/CampaignsList_NEW';
import CampaignLandingPagesScreen from '../../screens/gbpSetupScreen/campaignLandingPagesScreen/CampaignLandingPagesScreen';

const MainApp = () => {
	const [ { user }, dispatch ] = useStateValue();

	return (
		<Routes>
			<Route path="/login/*" element={<Login />} />
			<Route path="/admin/*" element={<Administration />} />
			<Route path="/setup/:campaignId/social" element={<CampaignSocialScreen />} />
			<Route path="/setup/:campaignId/directory" element={<CampaignDirectoryScreen />} />
			<Route path="/setup/:campaignId/keywords" element={<CampaignKeywordsScreen />} />
			<Route path="/setup/:campaignId/locations" element={<CampaignLocationsScreen />} />
			<Route path="/setup/:campaignId/services" element={<CampaignServicesScreen />} />
			<Route path="/setup/:campaignId/products" element={<CampaignProductsScreen />} />
			<Route path="/setup/:campaignId/files" element={<CampaignFilesScreen />} />
			<Route path="/setup/:campaignId/reviews" element={<CampaignReviewsScreen />} />
			<Route path="/setup/:campaignId/headline-and-desc" element={<CampaignHeadlineDescScreen />} />
			<Route path="/setup/:campaignId/design" element={<CampaignDesignScreen />} />
			<Route path="/setup/:campaignId/cta" element={<CampaignCtaScreen />} />
			<Route path="/setup/:campaignId/content" element={<CampaignContentScreen />} />
			<Route path="/setup/:campaignId/landing-pages" element={<CampaignLandingPagesScreen />} />
			<Route path="/setup/:campaignId/finish" element={<CampaignContentScreen />} />
			<Route path="/setup/:campaignId" element={<CampaignUpdateScreen />} />
			<Route path="/setup/:campaignId/view" element={<CampaignUpdateScreen isEdit={false} />} />
			<Route path="/setup" element={<GbpSetupScreen />} />
			<Route path="/campaigns/list/v2" element={<CampaignsList />} />
			<Route path="/campaigns/list" element={<CampaignsList_NEW />} />
			<Route path="/campaigns/report/:campaignId?" element={<CampaignReportScreen />} />
			<Route path="/tools/datagrid/v2/:campaignId?" element={<DataGridV2 />} />
			<Route path="/tools/datagrid" element={<DataGrid />} />
			<Route path="/tools/geocode" element={<Geocode />} />
			<Route path="/tools/distance" element={<DistanceFinder />} />
			<Route path="/settings" element={<ReportSettingsScreen />} />
			<Route path="/confirm-selection" element={<ConfirmBusinessSelectionScreen />} />
			<Route path="/:campaignId?" element={<GbpSelectScreen />} />
			<Route path="/sales/*" element={<Sales />} />
		</Routes>
	);
};

export default MainApp;
