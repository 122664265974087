import { Fragment, useEffect, useState } from 'react';
import './PaymentsList.css';
import TableFooter from '../../components/table/footer/TableFooter';
import Moment from 'react-moment';
import { formatCurrency } from '../../utils/Common';
import { ChargeUserPayment } from '../../Services';
import { toast } from 'react-toastify';

const PaymentsList = ({ payments, onSubmit, onListRefresh }) => {
	const handleChargeUserPayment = async (id, userEmail, amount) => {
		if (
			window.confirm(
				'You are going to charge user "' +
					userEmail +
					'" for amount of ' +
					formatCurrency(amount, true) +
					'. Continue?'
			)
		) {
			let response = await ChargeUserPayment(id);

			if (response.success) {
				toast.success('Payment process completed successfully');

				if (onSubmit) {
					onSubmit();
				}
			}
		}
	};

	const handleListRefresh = async () => {
		if (onListRefresh) {
			onListRefresh();
		}
	};

	return (
		<Fragment>
			<Fragment>
				<table className="items-list table table-responsive payments-list">
					<thead>
						<tr>
							<th scope="col">User</th>
							<th className="description text-truncate" scope="col">
								Description
							</th>
							<th className="status" scope="col">
								Price
							</th>
							<th className="status" scope="col">
								Status
							</th>
							<th className="date" scope="col">
								Due Date
							</th>
							<th className="payment-action" scope="col" />
						</tr>
					</thead>
					<tbody>
						{payments &&
							payments.length > 0 &&
							payments.map((payment, index) => (
								<tr key={payment.id}>
									<td>{payment.user.email}</td>
									<td className="description text-truncate">{payment.chargeDescription}</td>
									<td className="status">
										{payment.amount ? `${formatCurrency(payment.amount)}` : ''}
									</td>
									<td className="status">{payment.status}</td>
									<td className="date">
										{payment.dueDate ? <Moment format="MM/DD/YYYY">{payment.dueDate}</Moment> : ''}
									</td>
									<td className="task-action text-end">
										{payment.status === 'Scheduled' && (
											<button
												className="btn btn-link py-0"
												onClick={(e) =>
													handleChargeUserPayment(
														payment.id,
														payment.user.email,
														payment.amount
													)}
												title="Charge User Payment"
											>
												<i className="fa-regular fa-credit-card" />
											</button>
										)}
									</td>
								</tr>
							))}
					</tbody>
					<TableFooter
						list={payments}
						colspan={5}
						showRefreshButton={true}
						onListRefresh={handleListRefresh}
					/>
				</table>
			</Fragment>

			{/* {showDialog && (
				<PaymentDetailsDialog
					paymentId={editedPaymentId}
					onSubmit={handlePaymentSubmit}
					onClose={handleToggleDialog}
				/>
			)} */}
		</Fragment>
	);
};

export default PaymentsList;
