import { buildTimeValue } from '@testing-library/user-event/dist/utils';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CenterContainer from '../../components/centerContainer/CenterContainer';
import GoogleBusinessProfile from '../../components/googleBusinessProfile/GoogleBusinessProfile';
import Spinner from '../../components/spinner/Spinner';
import { GetBusiness, GetSalesBusiness, RemoveSalesBusiness, StoreSalesTask } from '../../utils/StorageUtil';
import './ConfirmBusinessSelectionScreen.css';
import { CreateSalesCompany } from '../../Services';
import { createGuid } from '../../utils/Common';
import { toast } from 'react-toastify';
import { useStateValue } from '../../StateProvider';
import GbpSetupScreen from '../gbpSetupScreen/GbpSetupScreen';
import GbpSelectScreen from '../gbpSelectScreen/GbpSelectScreen';

const ConfirmBusinessSelectionScreen = ({ approvedOrder = null }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSalesProcess, setIsSalesProcess ] = useState(false);
	const [ selectedBusiness, setSelectedBusiness ] = useState();
	const [ salesCompanyExists, setSalesCompanyExists ] = useState(false);
	const [ approvedOrderFinalStep, setApprovedOrderFinalStep ] = useState(false);
	const [ approvedOrderBackStep, setApprovedOrderBackStep ] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		let business = GetBusiness();

		if (window.location.pathname.indexOf('/sales') > -1) {
			business = GetSalesBusiness();
			setIsSalesProcess(true);
		}

		if (business) {
			setSelectedBusiness(business);
		} else {
			if (window.location.pathname.indexOf('/sales') > -1) {
				navigate('/sales/new');
			} else {
				navigate('');
			}
		}

		setIsLoading(false);
	}, []);

	const handleConfirm = async () => {
		if (approvedOrder && approvedOrder.id) {
			setApprovedOrderFinalStep(true);
			setApprovedOrderBackStep(false);
		} else if (isSalesProcess) {
			//save sales company
			let companyId = await saveSalesCompany();

			if (companyId) {
				//set temp task data
				StoreSalesTask({
					companyId : companyId,
					lat       : selectedBusiness.lat,
					lng       : selectedBusiness.lng
				});

				navigate('/sales/' + companyId + '/grid-select');
			}
		} else {
			navigate('/setup');
		}
	};

	const handleAnotherLocation = () => {
		if (approvedOrder && approvedOrder.id) {
			setApprovedOrderBackStep(true);
			setApprovedOrderFinalStep(false);
		} else if (isSalesProcess) {
			navigate('/sales/new');
		} else {
			navigate(`/${selectedBusiness.campaignId ? selectedBusiness.campaignId : ''}`);
		}
	};

	const saveSalesCompany = async () => {
		//let companyId = createGuid();
		let email = selectedBusiness.name.toLowerCase().replace(/ /g, '').replace(/[^\w-]+/g, '') + '@realwebsite.com';

		let cid, url;

		if (selectedBusiness.cid) {
			cid = selectedBusiness.cid;

			url = 'https://maps.google.com/?cid=' + cid;
		} else if (selectedBusiness.url) {
			url = selectedBusiness.url;
			const businessUrl = new URL(url);
			const businessUrlSearchParams = businessUrl.searchParams;

			if (businessUrlSearchParams) {
				cid = businessUrlSearchParams.get('cid');
			}
		}

		//parse address
		let addressArgs = selectedBusiness.address_components ? selectedBusiness.address_components : null;
		let address, city, state, postalCode, country;

		if (addressArgs) {
			let numberArgs = addressArgs.filter((x) => x.types.includes('street_number'));
			let streetArgs = addressArgs.filter((x) => x.types.includes('route'));
			let premiseArgs = addressArgs.filter((x) => x.types.includes('premise'));
			let subpremiseArgs = addressArgs.filter((x) => x.types.includes('subpremise'));
			let cityArgs = addressArgs.filter((x) => x.types.includes('locality'));
			let stateArgs = addressArgs.filter((x) => x.types.includes('administrative_area_level_1'));
			let zipArgs = addressArgs.filter((x) => x.types.includes('postal_code'));
			let countryArgs = addressArgs.filter((x) => x.types.includes('country'));

			let number = numberArgs && numberArgs.length > 0 && numberArgs[0].long_name ? numberArgs[0].long_name : '';
			let street =
				streetArgs && streetArgs.length > 0 && streetArgs[0].short_name ? streetArgs[0].short_name : '';
			let premise =
				premiseArgs && premiseArgs.length > 0 && premiseArgs[0].long_name ? ` ${premiseArgs[0].long_name}` : '';
			let subpremise =
				subpremiseArgs && subpremiseArgs.length > 0 && subpremiseArgs[0].long_name
					? ` ${subpremiseArgs[0].long_name}`
					: '';
			city = cityArgs && cityArgs.length > 0 && cityArgs[0].long_name ? cityArgs[0].long_name : '';
			state = stateArgs && stateArgs.length > 0 && stateArgs[0].short_name ? stateArgs[0].short_name : '';
			postalCode = zipArgs && zipArgs.length > 0 && zipArgs[0].short_name ? zipArgs[0].short_name : '';
			country = countryArgs && countryArgs.length > 0 && countryArgs[0].long_name ? countryArgs[0].long_name : '';

			if (number && street) {
				address = `${number} ${street}${premise}${subpremise}`;
			}
		}

		let data = {
			//id                : companyId,
			userId            : selectedBusiness.salesUserId ? selectedBusiness.salesUserId : user.id,
			name              : selectedBusiness.name,
			streetAddress1    : address,
			city              : city,
			state             : state,
			postalCode        : postalCode,
			country           : country,
			email             : email,
			phone             : selectedBusiness.formatted_phone_number,
			website           : selectedBusiness.website,
			googleCid         : cid,
			googlePlaceId     : selectedBusiness.place_id,
			googleBusinessUrl : url,
			lat               : selectedBusiness.lat,
			lng               : selectedBusiness.lng,
			status            : 'Active'
		};

		const response = await CreateSalesCompany(data);

		if (response.success) {
			RemoveSalesBusiness();

			return response.data.id;
		} else {
			setSalesCompanyExists(true);

			if (response.data && response.data.id) {
				//set temp task data
				StoreSalesTask({
					companyId : response.data.id,
					lat       : selectedBusiness.lat,
					lng       : selectedBusiness.lng
				});

				setTimeout(function() {
					navigate('/sales/' + response.data.id + '/tasks');
				}, 5000);
			}
		}
	};

	return (
		<CenterContainer inSidebar={approvedOrder}>
			{!isLoading ? (
				<Fragment>
					{approvedOrderFinalStep ? (
						<GbpSetupScreen approvedOrder={approvedOrder} />
					) : approvedOrderBackStep ? (
						<GbpSelectScreen approvedOrder={approvedOrder} />
					) : (
						<div className="business-confirm-container">
							{selectedBusiness && <GoogleBusinessProfile profile={selectedBusiness} />}

							<div className="buttons-container">
								<button type="button" className="btn btn-primary" onClick={handleConfirm}>
									<span>Continue</span>
								</button>
								<button type="button" className="btn btn-link" onClick={handleAnotherLocation}>
									<span>Find Another Location</span>
								</button>
							</div>
							{salesCompanyExists && (
								<div className="sales-company-exists-container">
									<span>
										Sales company with the same PlaceId already exists. Redirecting to company tasks
										page...
									</span>
								</div>
							)}
						</div>
					)}
				</Fragment>
			) : (
				<Spinner />
			)}
		</CenterContainer>
	);
};

export default ConfirmBusinessSelectionScreen;
