import { Fragment, useEffect, useState } from 'react';
import {
	AddCampaignProcess,
	GenerateSEOReport,
	IsCampaignRunningInQueue,
	MergeCampaignReportsIntoDb,
	RemoveCampaignProcess,
	UpdateSEOReportKeyword
} from '../../Services';
import Spinner from '../spinner/Spinner';
import './GenerateSeoReport.css';
import { toast } from 'react-toastify';

const GenerateSeoReport = ({
	onComplete,
	onStartReport,
	campaign,
	activeKeyword = null,
	reportId = null,
	buttonText = null
}) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ showSettings, setShowSettings ] = useState(false);
	const [ formData, setFormData ] = useState({
		runGridReport : true,
		runRankReport : true,
		gridApiType   : 'dseo',
		rankApiType   : 'dseo'
	});

	const toggleSettings = () => {
		if (!showSettings) {
			if (campaign.hostingOnly) {
				toast.error('Report cannot be generated bacause campaign is set as Hosting Only.');
				return;
			}
		}

		setShowSettings(!showSettings);
	};

	const handleChange = (e) => {
		const value = e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[e.target.name]: value
		});
	};

	const handleGenerateReport = async () => {
		let data = {
			id : campaign.id,
			...formData
		};

		if (onStartReport) onStartReport();

		//check if campaign is already running by auto process
		let respIsAutoRunning = await IsCampaignRunningInQueue(campaign.id);
		if (respIsAutoRunning && respIsAutoRunning.id) {
			toast.error('There is already auto-running report for this campaign.');
			setIsLoading(false);
			return;
		}

		setIsLoading(true);

		await addCampaignProcess('Report', data);

		// //check if report should be merged into db
		// if (!campaign.mergedIntoDb || campaign.mergedIntoDb === null) {
		// 	let mergeResponse = await MergeCampaignReportsIntoDb(campaign.id);

		// 	if (mergeResponse.success) {
		// 		campaign.mergedIntoDb = true;
		// 	}
		// }

		const response = await GenerateSEOReport(data);

		if (response.success) {
			if (onComplete) {
				onComplete(response);
			}
		} else {
			//remove current process
			await RemoveCampaignProcess(campaign.id, {
				campaignId : campaign.id,
				type       : 'Report'
			});

			toast.error(response.message);
			console.error(response.message);
		}

		setIsLoading(false);
	};

	const handleUpdateReportKeyword = async () => {
		if (reportId && activeKeyword) {
			let data = {
				id       : campaign.id,
				keywords : [ activeKeyword.keyword ],
				...formData
			};

			if (onStartReport) onStartReport();

			setIsLoading(true);

			const response = await UpdateSEOReportKeyword(reportId, data);

			if (response.success) {
				toast.success('Grid results successfully updated for keyword: ' + activeKeyword.keyword);

				if (onComplete) {
					onComplete(response);
				}
			} else {
				console.error(response);
			}

			setIsLoading(false);
			toggleSettings();
		}
	};

	const addCampaignProcess = async (type, inputData) => {
		let data = {
			campaignId : campaign.id,
			type       : type,
			status     : 'Running',
			data       : JSON.stringify(inputData)
		};

		const response = await AddCampaignProcess(campaign.id, data);

		if (!response.success) {
			console.error(response);
		}
	};

	return (
		<div className="generate-seo-report">
			{!showSettings && (
				<div className="btn btn-primary" onClick={toggleSettings}>
					{buttonText || 'Generate Report'}
				</div>
			)}
			{showSettings && (
				<Fragment>
					{!isLoading ? (
						<Fragment>
							<div className="btn btn-outline-primary" onClick={toggleSettings}>
								Cancel
							</div>
							<div className="generate-report-settings text-bg-light p-3 rounded mt-3">
								<div className="generate-report-settings-item">
									<div className="generate-report-settings-item-label">Run Grid Report?</div>
									<div className="generate-report-settings-item-input">
										<input
											type="checkbox"
											className="form-check-input"
											name="runGridReport"
											value={formData.runGridReport}
											defaultChecked={formData.runGridReport}
											onChange={handleChange}
										/>
									</div>
								</div>

								{!reportId && (
									<div className="generate-report-settings-item">
										<div className="generate-report-settings-item-label">Run Rank Report?</div>
										<div className="generate-report-settings-item-input">
											<input
												type="checkbox"
												className="form-check-input"
												name="runRankReport"
												value={formData.runRankReport}
												defaultChecked={formData.runRankReport}
												onChange={handleChange}
											/>
										</div>
									</div>
								)}

								<div className="generate-report-settings-item">
									<div className="generate-report-settings-item-label">Grid Api</div>
									<div className="generate-report-settings-item-input">
										<select
											className="form-select w-auto"
											name="gridApiType"
											value={formData.gridApiType}
											onChange={handleChange}
										>
											<option value="serp">Serp Api</option>
											{/* <option value="google">Google Api</option> */}
											<option value="dseo">DataForSeo Api</option>
											<option value="dseotask">DataForSeo Api (Task)</option>
										</select>
									</div>
								</div>

								{!reportId && (
									<div className="generate-report-settings-item">
										<div className="generate-report-settings-item-label">Rank Api</div>
										<div className="generate-report-settings-item-input">
											<select
												className="form-select w-auto"
												name="rankApiType"
												value={formData.rankApiType}
												onChange={handleChange}
											>
												<option value="serp">Serp Api</option>
												{/* <option value="google">Google Api</option> */}
												<option value="dseo">DataForSeo Api</option>
											</select>
										</div>
									</div>
								)}

								<button
									type="button"
									className="btn btn-primary mt-3"
									onClick={reportId ? handleUpdateReportKeyword : handleGenerateReport}
									disabled={isLoading}
								>
									{buttonText || 'Generate Report'}
								</button>
							</div>
						</Fragment>
					) : (
						<div className="text-center">
							<Spinner />
							<small className="text-muted d-block mt-2">
								Generating report... Please wait, this may take a couple of minutes...
							</small>
						</div>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default GenerateSeoReport;
