import { Fragment, useCallback, useEffect, useState } from 'react';
import {
	AddCannedComment,
	DeleteCannedComment,
	GetCampaign,
	GetCampaignLastReportTop3RankPages,
	GetCampaignReportTop3RankPages,
	GetCannedComments,
	GetReportHistoryDetails,
	GetSalesUsers,
	SendReportByEmail,
	UpdateCampaignReportDate,
	UpdateCampaignStatus,
	UpdateCannedCommentsIndexes
} from '../../../Services';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../StateProvider';
import ModalDialog from '../../modalDialog/ModalDialog';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { useSearchParams } from 'react-router-dom';
import Moment from 'react-moment';
import moment from 'moment';
import Creatable from 'react-select/creatable';
import { calculateReportDateForNextMonth, copyToClipboard, validateDayOfMonth } from '../../../utils/Common';
import Spinner from '../../spinner/Spinner';
import './SendReportDialog.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DndCard from '../../../components/dragAndDrop/DragAndDropCard';

const SendReportDialog = ({ campaignId, reportId, compareToReportId, targetKeyword, reportHistoryId, onClose }) => {
	const [ isSubmittingSales, setIsSubmittingSales ] = useState(false);
	const [ isSubmittingCustomer, setIsSubmittingCustomer ] = useState(false);
	const [ isSettingAsSent, setIsSettingAsSent ] = useState(false);
	const [ campaign, setCampaign ] = useState();
	const [ reportHistory, setReportHistory ] = useState();
	const [ queryParameters ] = useSearchParams();
	const [ sendReportFormData, setSendReportFormData ] = useState();
	const [ sendReportComments, setSendReportComments ] = useState('');
	const [ salesUsers, setSalesUsers ] = useState([]);
	const [ { host, reportSendToEmail, reportSendEmailCC }, dispatch ] = useStateValue();
	const emailSubjectChoices = [
		{ label: 'Map Boost Campaign Launched', value: 'Map Boost Campaign Launched' },
		{ label: 'Map Boost 30 Day Campaign Report', value: 'Map Boost 30 Day Campaign Report' },
		{ label: 'Map Boost Monthly Report', value: 'Map Boost Monthly Report' }
	];
	const [ subject, setSubject ] = useState();
	const [ selectedSalesUser, setSelectedSalesUser ] = useState();
	const campaignLaunchedDefaultText = `Your Map Boost campaign is live!

Over the next 30 days we are going to work on getting the directory site pages organically ranked and then we will run another report that will show our progress.

We will continue to update content and re-submit to Google over the next months and each month send an update report to track progress.`;

	const campaign30DayReportDefaultText = `You have over {TotalPages} directory site pages organically ranked at the top of Google search. 

Over the next 30 days we are going to work on getting more of the directory site pages organically ranked and then we will run another report that will show our progress.

We will continue to update content and re-submit to Google over the next months and each month send an update report to track progress.`;

	const campaignMonthlyReportDefaultText = `We update and re-submit content every month to Google to get more organic ranked directory pages. We highly recommend getting more frequent and consistent reviews to increase and maintain a high profile quality score.`;
	const [ cannedComments, setCannedComments ] = useState(false);
	const [ cannedCommentsTags, setCannedCommentsTags ] = useState();
	const [ selectedCannedComments, setSelectedCannedComments ] = useState();
	const [ showCannedCommentsDialog, setShowCannedCommentsDialog ] = useState(false);
	const [ isSavingCannedComments, setIsSavingCannedComments ] = useState(false);
	const [ showAddNewCannedComment, setShowAddNewCannedComment ] = useState(false);
	const [ cannedCommentFormData, setCannedCommentFormData ] = useState({});
	const [ cannedCommentsFormTags, setCannedCommentsFormTags ] = useState();
	const [ top3TotalPages, setTop3TotalPages ] = useState();
	const mergeFields = [ '{TotalPages}' ];

	useEffect(
		() => {
			if (campaignId) {
				getCampaign();
				getCannedComments();
				getLastReportTop3PagesCount();
			}
		},
		[ campaignId ]
	);

	useEffect(
		() => {
			if (campaign) {
				getSalesUsers();
				getCampaignReportHistory();
			}
		},
		[ campaign ]
	);

	useEffect(
		() => {
			if (campaign) {
				let tempSendReportFormData = {
					ahOrderId           : campaign.ahOrderId,
					stripeSubId         : campaign.stripeSubId,
					campaignDateCreated : campaign.dateCreated,
					lastReportDate      : campaign.lastReportDate,
					nextReportDate      : campaign.nextReportDate
						? moment(campaign.nextReportDate).format('yyyy-MM-DD')
						: null,
					reportDayOfMonth    : campaign.reportDayOfMonth || 1,
					customerEmail       : campaign.user ? campaign.user.email : ''
				};
				let tempComments;

				if (salesUsers && salesUsers.length) {
					//set sales rep control
					let defaultSalesUser = { value: '', label: reportSendToEmail }; //default value
					let tempSelectedSalesUser;

					if (campaign.salesRepId) {
						tempSelectedSalesUser = salesUsers.find((x) => x.value === campaign.salesRepId);
					} else {
						tempSelectedSalesUser = salesUsers.find((x) => x.label === reportSendToEmail);
					}

					if (reportHistory) {
						if (reportHistory.sentTo) {
							let historySentTo = reportHistory.sentTo.replace(',' + reportSendEmailCC, '');
							tempSelectedSalesUser = { value: '', label: historySentTo };
						}

						tempSendReportFormData.customerEmail =
							reportHistory.customerEmail || reportHistory.campaignUserEmail;
						tempSendReportFormData.subject = reportHistory.subject;
						tempSendReportFormData.videoUrl = reportHistory.videoUrl;
						tempComments = reportHistory.comments;
					}

					setSelectedSalesUser(tempSelectedSalesUser || defaultSalesUser);
				}

				setSendReportFormData({ ...sendReportFormData, ...tempSendReportFormData });
				setSendReportComments(tempComments);
			}
		},
		[ salesUsers, reportHistory ]
	);

	//subject
	useEffect(
		() => {
			if (emailSubjectChoices && reportHistory && reportHistory.subject) {
				let tempSubjectObj = emailSubjectChoices.find((x) => x.label === reportHistory.subject);

				if (!tempSubjectObj) {
					tempSubjectObj = { value: '', label: reportHistory.subject };
				}

				setSubject(tempSubjectObj);

				let tempSendReportFormData = sendReportFormData || {};
				tempSendReportFormData.subject = tempSubjectObj.label;
			} else {
				setSubject({ value: '', label: '' });
			}
		},
		[ reportHistory ]
	);

	const getCampaign = async () => {
		const response = await GetCampaign(campaignId);

		if (response) {
			setCampaign(response);
		}
	};

	const getSalesUsers = async () => {
		if (campaign) {
			let response = await GetSalesUsers();

			if (response && response.length) {
				//sort users alphabetically
				response = response.sort((a, b) => a.firstName.localeCompare(b.firstName));

				let tempSalesUsers = new Array();
				for (let i = 0; i < response.length; i++) {
					tempSalesUsers.push({
						value : response[i].id,
						label : response[i].email
					});
				}

				setSalesUsers(tempSalesUsers);
			}
		}
	};

	const getCampaignReportHistory = async () => {
		if (reportHistoryId) {
			const response = await GetReportHistoryDetails(reportHistoryId);

			if (response) {
				setReportHistory(response);
			} else {
				setReportHistory();
			}
		}
	};

	const getLastReportTop3PagesCount = async () => {
		const response = await GetCampaignLastReportTop3RankPages(campaignId);

		if (response) {
			setTop3TotalPages(response);
		}
	};

	const getReportShareUrl = () => {
		var url = new URL(`${host}/view/report/v5/${campaign.id}/`);

		if (targetKeyword && targetKeyword.keyword) {
			url.searchParams.set('keyword', targetKeyword.keyword);
		}

		if (compareToReportId) {
			url.searchParams.append('startDate', compareToReportId);
		}

		if (reportId) {
			url.searchParams.append('endDate', reportId);
		}

		return url.href;
	};

	const handleSendReportFormDataChange = async (e) => {
		if (e.target.name === 'reportDayOfMonth') {
			let dayOfMonth = validateDayOfMonth(e.target.value);

			//set next report date
			setSendReportFormData({
				...sendReportFormData,
				[e.target.name]: dayOfMonth,
				nextReportDate  : calculateReportDateForNextMonth(dayOfMonth)
			});
		} else if (e.target.name === 'comments') {
			setSendReportComments(e.target.value);
		} else {
			setSendReportFormData({
				...sendReportFormData,
				[e.target.name]: e.target.value
			});
		}
	};

	const handleSalesUsersChange = (selectedOption) => {
		if (selectedOption) {
			setSelectedSalesUser(selectedOption);
		} else {
			setSelectedSalesUser();
		}
	};

	const handleEmailSubjectChange = (selectedSubject) => {
		if (selectedSubject) {
			let tempSendReportFormData = sendReportFormData;
			tempSendReportFormData.subject = selectedSubject.label;

			let existingComments = sendReportComments || '';
			let tempComments;

			// //prepend default text for comments
			// if (selectedSubject.value === 'Map Boost Campaign Launched') {
			// 	tempComments = campaignLaunchedDefaultText + ' ' + existingComments;
			// } else if (selectedSubject.value === 'Map Boost 30 Day Campaign Report') {
			// 	tempComments =
			// 		(top3TotalPages
			// 			? campaign30DayReportDefaultText.replace('{TotalPages}', top3TotalPages)
			// 			: campaign30DayReportDefaultText) +
			// 		' ' +
			// 		existingComments;
			// } else if (selectedSubject.value === 'Map Boost Monthly Report') {
			// 	tempComments = campaignMonthlyReportDefaultText + ' ' + existingComments;
			// }

			// if (tempComments) {
			// 	setSendReportComments(tempComments);
			// }

			setSendReportFormData(tempSendReportFormData);
		}
	};

	const sendReport = async (recipientType) => {
		let isValid = document.querySelector('.send-report-form').reportValidity();

		if (!isValid) {
			return;
		}

		if (recipientType === 'sales') setIsSubmittingSales(true);
		else setIsSubmittingCustomer(true);

		//set comments
		let tempComments = sendReportComments ? sendReportComments + '\n\n' : '';

		if (selectedCannedComments && selectedCannedComments.length) {
			for (let i = 0; i < selectedCannedComments.length; i++) {
				tempComments +=
					selectedCannedComments[i].value + (i === selectedCannedComments.length - 1 ? '' : '\n\n');
			}
		}

		let data = {
			...sendReportFormData,
			campaignId : campaign.id,
			reportUrl  : getReportShareUrl(),
			videoUrl   : sendReportFormData.videoUrl,
			comments   : tempComments
		};

		data.recipientType = recipientType;

		if (recipientType === 'customer') {
			//CC recipients
			data.sendTo = (selectedSalesUser ? selectedSalesUser.label + ',' : '') + reportSendEmailCC;
		} else {
			if (!selectedSalesUser) {
				toast.error('Select sales user to send email to');
				return;
			}

			data.sendTo = selectedSalesUser.label;
		}

		if (queryParameters && queryParameters.get('hid')) {
			data.id = queryParameters.get('hid');
		}

		const response = await SendReportByEmail(campaign.id, data);

		if (response.success) {
			if (onClose) {
				onClose();
			}

			toast.success('Report sent successfully.');

			setSendReportFormData({
				subject             : sendReportFormData.subject,
				customerEmail       : sendReportFormData.customerEmail,
				ahOrderId           : campaign.ahOrderId,
				campaignDateCreated : campaign.dateCreated,
				lastReportDate      : campaign.lastReportDate,
				nextReportDate      : sendReportFormData.nextReportDate,
				reportDayOfMonth    : sendReportFormData.reportDayOfMonth
			});

			//copy report url to clipboard
			if (response.data) {
				// if (recipientType === 'customer') {
				// 	copyToClipboard(`${host}/customer/report/${response.data.id}`, '');
				// } else {
				// 	copyToClipboard(response.data.reportUrl, '');
				// }
			}

			//activate campaign if it's in progress
			if (campaign.status === 'New') {
				activateCampaign();
			}
		} else {
			if (onClose) {
				onClose();
			}

			toast.error(response.message);
		}

		if (recipientType === 'sales') setIsSubmittingSales(false);
		else setIsSubmittingCustomer(false);
	};

	const setAsSentReport = async () => {
		let isValid = document.querySelector('.send-report-form').reportValidity();

		if (!isValid) {
			return;
		}

		setIsSettingAsSent(true);

		//set comments
		let tempComments = sendReportComments ? sendReportComments + '\n\n' : '';

		if (selectedCannedComments && selectedCannedComments.length) {
			for (let i = 0; i < selectedCannedComments.length; i++) {
				tempComments +=
					selectedCannedComments[i].value + (i === selectedCannedComments.length - 1 ? '' : '\n\n');
			}
		}

		let data = {
			...sendReportFormData,
			campaignId : campaign.id,
			reportUrl  : getReportShareUrl(),
			videoUrl   : sendReportFormData.videoUrl,
			comments   : tempComments,
			sendEmail  : false
		};

		if (queryParameters && queryParameters.get('hid')) {
			data.id = queryParameters.get('hid');
		}

		const response = await SendReportByEmail(campaign.id, data);

		if (response.success) {
			toast.success('Report set as sent successfully.');

			setSendReportFormData({
				subject             : sendReportFormData.subject,
				customerEmail       : sendReportFormData.customerEmail,
				ahOrderId           : campaign.ahOrderId,
				campaignDateCreated : campaign.dateCreated,
				lastReportDate      : campaign.lastReportDate,
				nextReportDate      : sendReportFormData.nextReportDate,
				reportDayOfMonth    : sendReportFormData.reportDayOfMonth
			});

			// //copy report url to clipboard
			// if (response.data) {
			// 	copyToClipboard(`${host}/customer/report/${response.data.id}`, '');
			// }

			//activate campaign if it's in progress
			if (campaign.status === 'New') {
				activateCampaign();
			}
		}

		if (onClose) {
			onClose();
		}
		setIsSettingAsSent(false);
	};

	const updateReportDates = async () => {
		//update report dates
		let response = await UpdateCampaignReportDate(campaign.id, {
			nextReportDate   : sendReportFormData.nextReportDate,
			reportDayOfMonth : sendReportFormData.reportDayOfMonth
		});

		if (response.success) {
			toast.success('Report dates successfully updated');
		}
	};

	const activateCampaign = async () => {
		setCampaign({
			...campaign,
			status : 'Active'
		});

		let data = {
			status : 'Active'
		};

		const response = await UpdateCampaignStatus(campaign.id, data);

		if (!response.success) {
			console.log('Campaign was not activated. Reason: ' + response.message);
		}
	};

	const handleOnCloseDialog = () => {
		if (onClose) {
			onClose();
		}
	};

	const getCannedComments = async () => {
		var response = await GetCannedComments();

		if (response.success && response.data && response.data.length) {
			let tempCannedComments = response.data;

			for (let i = 0; i < tempCannedComments.length; i++) {
				tempCannedComments[i].show = true;
				tempCannedComments[i].checked = false;
			}

			let tempTags = [];

			for (let i = 0; i < tempCannedComments.length; i++) {
				let comment = tempCannedComments[i];
				comment.show = true;

				if (comment.tags && comment.tags.length) {
					for (let j = 0; j < comment.tags.length; j++) {
						let tag = comment.tags[j];

						if (!tempTags.some((e) => e.name === tag.name)) {
							tempTags.push(tag);
						}
					}
				}
			}

			if (tempTags.length) {
				for (let i = 0; i < tempTags.length; i++) {
					tempTags[i].checked = true;
				}
			}

			setCannedComments(tempCannedComments);
			setCannedCommentsTags(tempTags);
		}
	};

	const toggleCannedCommentsDialog = async (e) => {
		if (e) {
			e.preventDefault();
		}

		setShowCannedCommentsDialog(!showCannedCommentsDialog);
	};

	const handleCannedCommentsChange = (e) => {
		let tempCannedComments = cannedComments.map((x) => x);

		if (e.target.type === 'checkbox') {
			let comment = tempCannedComments.find((x) => x.id === e.target.name);

			if (comment) {
				comment.checked = e.target.checked;
			}

			let tempTags = cannedCommentsTags.map((x) => x);

			let selectedTagNames = tempTags.filter((x) => x.checked === true).map((x) => x.name);

			if (!comment.checked) {
				if (comment.tags && comment.tags.length) {
					comment.show = comment.tags.some((x) => selectedTagNames.includes(x.name));
				}
			}
		}

		setCannedComments(tempCannedComments);
	};

	const handleCannedCommentTagSelected = (e) => {
		let tempTags = cannedCommentsTags.map((x) => x);

		let tag = tempTags.find((x) => x.name === e.target.name);

		if (tag) {
			tag.checked = e.target.className.indexOf('btn-success') > -1 ? false : true;
		}

		let selectedTagNames = tempTags.filter((x) => x.checked === true).map((x) => x.name);

		let tempComments = cannedComments.map((x) => x);

		for (let i = 0; i < tempComments.length; i++) {
			let currentComment = tempComments[i];

			if (!currentComment.checked) {
				if (currentComment.tags && currentComment.tags.length) {
					currentComment.show = currentComment.tags.some((x) => selectedTagNames.includes(x.name));
				}
			}
		}

		setCannedCommentsTags(tempTags);
		setCannedComments(tempComments);
	};

	const saveCannedComments = () => {
		if (cannedComments) {
			let tempSelectedCannedComments = cannedComments.filter((x) => x.checked === true);

			if (tempSelectedCannedComments) {
				tempSelectedCannedComments = tempSelectedCannedComments.sort(function(a, b) {
					return a.index - b.index;
				});

				toggleCannedCommentsDialog();
				setSelectedCannedComments(tempSelectedCannedComments);
			}
		}
	};

	const handleCannedCommentsFormDataChange = (e) => {
		setCannedCommentFormData({
			...cannedCommentFormData,
			[e.target.name]: e.target.value
		});
	};

	const showAddNewCannedCommentArea = async () => {
		setShowAddNewCannedComment(true);

		if (cannedCommentsTags && cannedCommentsTags.length) {
			let selectedTagNames = cannedCommentsTags.map((x) => x.name);

			setCannedCommentsFormTags(selectedTagNames.join('\n'));
		}
	};

	const saveNewCannedComment = async () => {
		setIsSavingCannedComments(true);

		let data = cannedCommentFormData;
		data.show = true;

		if (cannedCommentsFormTags) {
			let dataTags = [];
			cannedCommentsFormTags.split('\n').forEach((tagName) => {
				dataTags.push({ name: tagName });
			});

			data.tags = dataTags;
		}

		let response = await AddCannedComment(data);

		if (response.success && response.data) {
			let newComment = response.data;
			newComment.show = true;

			let tempComments = cannedComments.map((x) => x);
			tempComments.push(newComment);

			setCannedComments(tempComments);
			handleUpdateTags(tempComments);

			setShowAddNewCannedComment(false);
		}

		setIsSavingCannedComments(false);
	};

	const handleUpdateTags = (comments) => {
		if (comments) {
			let tempTags = [];

			for (let i = 0; i < comments.length; i++) {
				let comment = comments[i];

				if (comment.tags && comment.tags.length) {
					for (let j = 0; j < comment.tags.length; j++) {
						let tag = comment.tags[j];

						if (!tempTags.some((e) => e.name === tag.name)) {
							tempTags.push(tag);
						}
					}
				}
			}

			if (tempTags.length) {
				for (let i = 0; i < tempTags.length; i++) {
					let tagIsChecked = true;

					//check if tag was selected
					if (cannedCommentsTags && cannedCommentsTags.length) {
						let existingTag = cannedCommentsTags.find((x) => x.name === tempTags[i].name);

						if (existingTag) {
							tagIsChecked = existingTag.checked;
						}
					}

					tempTags[i].checked = tagIsChecked;
				}
			}

			setCannedCommentsTags(tempTags);
		}
	};

	const handleCannedCommentMove = useCallback(
		(dragIndex, hoverIndex) => {
			const dragItem = cannedComments[dragIndex];
			const hoverItem = cannedComments[hoverIndex];

			// Swap places of dragItem and hoverItem in the cannedComments array
			setCannedComments((cannedComments) => {
				const updatedCannedComments = [ ...cannedComments ];
				updatedCannedComments[dragIndex] = hoverItem;
				updatedCannedComments[hoverIndex] = dragItem;

				//update index
				updatedCannedComments[hoverIndex].index = hoverIndex;
				updatedCannedComments[dragIndex].index = dragIndex;

				//setItemsIndex(updatedCannedComments);
				UpdateCannedCommentsIndexes(updatedCannedComments);

				return updatedCannedComments;
			});
		},
		[ cannedComments ]
	);

	const deleteCannedComment = useCallback(
		(id) => {
			if (window.confirm('Are you sure that you want to delete canned comment?')) {
				setIsSavingCannedComments(true);

				setCannedComments((cannedComments) => {
					const tempCannedComments = [ ...cannedComments ];

					//delete from database
					let response = DeleteCannedComment(id);

					if (response) {
						if (cannedComments) {
							const deletedCommentIndex = tempCannedComments.findIndex((el) => el.id === id);
							if (deletedCommentIndex > -1) {
								tempCannedComments.splice(deletedCommentIndex, 1);
							}

							handleUpdateTags(tempCannedComments);
						}
					}

					return tempCannedComments;
				});
			}
		},
		[ cannedComments ]
	);

	return (
		<Fragment>
			<ModalDialog position="right-sidebar" className="text-start">
				<ModalDialogContent align="start">
					{sendReportFormData && salesUsers && salesUsers.length && selectedSalesUser ? (
						<form className="row send-report-form">
							<div className="col-12 mb-3">
								<label htmlFor="salesUsersSelect" className="form-label">
									Send To:
								</label>
								{salesUsers &&
								salesUsers.length &&
								selectedSalesUser && (
									<Creatable
										options={salesUsers}
										isClearable
										required
										defaultValue={selectedSalesUser}
										onChange={handleSalesUsersChange}
									/>
								)}
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="customerEmail" className="form-label">
									Customer Email:
								</label>
								<input
									type="email"
									className="form-control"
									name="customerEmail"
									value={sendReportFormData.customerEmail || ''}
									onChange={handleSendReportFormDataChange}
									required
									disabled={isSubmittingSales || isSubmittingCustomer}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="videoUrl" className="form-label">
									Loom Video:
								</label>
								<input
									type="text"
									className="form-control"
									name="videoUrl"
									value={sendReportFormData.videoUrl || ''}
									onChange={handleSendReportFormDataChange}
									disabled={isSubmittingSales || isSubmittingCustomer}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="subject" className="form-label">
									Subject:
								</label>
								{emailSubjectChoices &&
								subject && (
									<Creatable
										options={emailSubjectChoices}
										isClearable
										required
										defaultValue={subject}
										onChange={handleEmailSubjectChange}
									/>
								)}
							</div>
							<div className="col-12 mb-3">
								<div className="input-group">
									<label htmlFor="comments" className="form-label col-4">
										Comments:
									</label>
									<div className="col-8 text-end">
										{selectedCannedComments &&
										selectedCannedComments.length > 0 && (
											<label className="form-label canned-comments-label">
												{`${selectedCannedComments.length} comment${selectedCannedComments.length >
												1
													? 's'
													: ''} checked`}
											</label>
										)}
										<button
											className="btn btn-link btn-canned-comments-settings"
											onClick={(e) => toggleCannedCommentsDialog(e)}
										>
											<i className="fa-solid fa-gear me-0" />
										</button>
									</div>
								</div>
								<textarea
									className="form-control"
									name="comments"
									value={sendReportComments || ''}
									onChange={handleSendReportFormDataChange}
									rows={5}
									disabled={isSubmittingSales || isSubmittingCustomer}
								/>
							</div>
							<div className="col-12 mb-2">
								<label className="form-label one-line-input-label">Total Pages:</label>
								<span className="ms-2">{top3TotalPages}</span>
							</div>
							<div className="col-12 mb-2">
								<label className="form-label one-line-input-label">AH Order Id:</label>
								<span className="ms-2">{sendReportFormData.ahOrderId}</span>
							</div>
							<div className="col-12 mb-2">
								<label className="form-label one-line-input-label">Subscription Id:</label>
								<span className="ms-2">{sendReportFormData.stripeSubId}</span>
							</div>
							<div className="col-12 mb-2">
								<label className="form-label one-line-input-label">Date Created:</label>
								<span className="ms-2">
									{sendReportFormData.campaignDateCreated && (
										<Moment format="MM/DD/YYYY">{sendReportFormData.campaignDateCreated}</Moment>
									)}
								</span>
							</div>
							<div className="col-12 mb-2">
								<label className="form-label one-line-input-label">Last Report Date:</label>
								<span className="ms-2">
									{sendReportFormData.lastReportDate && (
										<Moment format="MM/DD/YYYY">{sendReportFormData.lastReportDate}</Moment>
									)}
								</span>
							</div>

							<div className="row mb-0">
								<div className="col-5">
									<label htmlFor="reportDayOfMonth" className="form-label">
										Report Day of Month:
									</label>
								</div>
								<div className="col-5">
									<label htmlFor="nextReportDate" className="form-label">
										Next Report Date:
									</label>
								</div>
							</div>

							<div className="row mb-3">
								<div className="col-5">
									<input
										type="number"
										className="form-control"
										name="reportDayOfMonth"
										value={sendReportFormData.reportDayOfMonth || 1}
										onChange={handleSendReportFormDataChange}
										min={1}
										max={31}
									/>
								</div>
								<div className="col-5">
									<input
										type="date"
										className="form-control"
										name="nextReportDate"
										value={sendReportFormData.nextReportDate || ''}
										onChange={handleSendReportFormDataChange}
										disabled={isSubmittingSales || isSubmittingCustomer}
									/>
								</div>
								<div className="col-2">
									<button className="btn btn-primary" type="button" onClick={updateReportDates}>
										Update
									</button>
								</div>
							</div>
						</form>
					) : (
						<div className="text-center">
							<Spinner />
						</div>
					)}
				</ModalDialogContent>
				<ModalDialogButtons>
					<button className="btn btn-primary" type="button" onClick={() => sendReport('sales')}>
						{isSubmittingSales ? (
							<Fragment>
								<span className="spinner-border m-0 me-2" />
								<span>Sending</span>
							</Fragment>
						) : (
							<span>Send Sales</span>
						)}
					</button>
					<button
						className="btn btn-primary"
						type="button"
						onClick={() => sendReport('customer')}
						disabled={campaign && campaign.ahOrderId && campaign.ahOrderId !== ''}
					>
						{isSubmittingCustomer ? (
							<Fragment>
								<span className="spinner-border m-0 me-2" />
								<span>Sending</span>
							</Fragment>
						) : (
							<span>Send Customer</span>
						)}
					</button>
					{(!reportHistory || (reportHistory && reportHistory.status === 'Ready')) && (
						<button className="btn btn-primary" type="button" onClick={setAsSentReport}>
							{isSettingAsSent ? (
								<Fragment>
									<span className="spinner-border m-0 me-2" />
									<span>Setting As Sent</span>
								</Fragment>
							) : (
								<span>Set As Sent</span>
							)}
						</button>
					)}
					<button className="btn btn-outline-primary ms-2" type="button" onClick={handleOnCloseDialog}>
						Cancel
					</button>
				</ModalDialogButtons>
			</ModalDialog>

			{showCannedCommentsDialog && (
				<ModalDialog position="right-sidebar" className="text-start">
					<ModalDialogContent align="start">
						<h4 className="mb-4">
							Canned Comments
							<button className="btn btn-link p-1 ms-3" onClick={() => showAddNewCannedCommentArea()}>
								<i className="fa-solid fa-plus" />
								<span>Add Comment</span>
							</button>
						</h4>

						{showAddNewCannedComment ? (
							<div className="row">
								{mergeFields &&
								mergeFields.length && (
									<div className="col-12 mb-3">
										<label htmlFor="name" className="form-label me-3">
											Merge Fields:
										</label>
										{mergeFields.map((mergeField, mfIndex) => (
											<button
												key={mfIndex}
												className="btn btn-success canned-comment-tag"
												value={mergeField}
												onClick={() => copyToClipboard(mergeField)}
												title="Clik To Copy"
											>
												{mergeField}
											</button>
										))}
									</div>
								)}
								<div className="col-12 mb-3">
									<label htmlFor="name" className="form-label">
										Name:
									</label>
									<input
										type="text"
										className="form-control"
										name="name"
										value={cannedCommentFormData.name || ''}
										onChange={handleCannedCommentsFormDataChange}
										disabled={isSavingCannedComments}
										required
									/>
								</div>
								<div className="col-12 mb-3">
									<label htmlFor="value" className="form-label">
										Text:
									</label>
									<textarea
										className="form-control"
										name="value"
										value={cannedCommentFormData.value || ''}
										onChange={handleCannedCommentsFormDataChange}
										disabled={isSavingCannedComments}
										rows={5}
										required
									/>
								</div>
								<div className="col-12">
									<label htmlFor="tags" className="form-label">
										Tags:
									</label>
									<textarea
										className="form-control"
										name="tags"
										value={cannedCommentsFormTags || ''}
										onChange={(e) => setCannedCommentsFormTags(e.target.value)}
										disabled={isSavingCannedComments}
										rows={5}
										required
									/>
								</div>
							</div>
						) : (
							<Fragment>
								{cannedCommentsTags &&
								cannedCommentsTags.length && (
									<div className="row">
										<div className="col-12 mb-4">
											{cannedCommentsTags.map((tag, tagIndex) => {
												return (
													<button
														className={`btn ${tag.checked && tag.checked === true
															? 'btn-success'
															: 'btn-light'} canned-comment-tag`}
														key={tagIndex}
														name={tag.name}
														onClick={handleCannedCommentTagSelected}
													>
														{tag.name}
													</button>
												);
											})}
										</div>
									</div>
								)}

								{cannedComments &&
								cannedComments.length && (
									<DndProvider backend={HTML5Backend}>
										{cannedComments.map((comment) => (
											<Fragment key={comment.id}>
												{comment.show && (
													<DndCard
														index={comment.index}
														id={comment.id ? comment.id : ''}
														key={comment.index}
														moveCard={handleCannedCommentMove}
														text={
															<div className="row canned-comment-item">
																<div className="col-12">
																	<div className="form-check border-top py-2 mb-0">
																		<div className="">
																			<input
																				type="checkbox"
																				className="form-check-input"
																				id={comment.id}
																				name={comment.id}
																				onChange={handleCannedCommentsChange}
																				checked={comment.checked}
																			/>
																			<label
																				htmlFor={comment.id}
																				className="form-check-label comment-label"
																			>
																				{comment.value}
																			</label>
																			<div className="d-inline-block float-end">
																				<button
																					onClick={() =>
																						deleteCannedComment(comment.id)}
																					className="btn btn-link p-0 canned-comment-action-btn"
																					title="DeleteComment"
																				>
																					<i className="fa-regular fa-circle-xmark text-dark" />
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														}
													/>
												)}
											</Fragment>
										))}
									</DndProvider>
								)}
							</Fragment>
						)}
					</ModalDialogContent>
					<ModalDialogButtons>
						{showAddNewCannedComment ? (
							<button className="btn btn-primary" type="button" onClick={() => saveNewCannedComment()}>
								{isSavingCannedComments ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Adding</span>
									</Fragment>
								) : (
									<span>Add Comment</span>
								)}
							</button>
						) : (
							<button className="btn btn-primary" type="button" onClick={() => saveCannedComments()}>
								{isSavingCannedComments ? (
									<Fragment>
										<span className="spinner-border m-0 me-2" />
										<span>Saving</span>
									</Fragment>
								) : (
									<span>Save</span>
								)}
							</button>
						)}

						<button
							className="btn btn-outline-primary ms-2"
							type="button"
							onClick={() => {
								if (showAddNewCannedComment) {
									setShowAddNewCannedComment(false);
								} else {
									toggleCannedCommentsDialog();
								}
							}}
						>
							Cancel
						</button>
					</ModalDialogButtons>
				</ModalDialog>
			)}
		</Fragment>
	);
};

export default SendReportDialog;
