import { Fragment, useEffect, useState, useRef } from 'react';
import Spinner from '../../../components/spinner/Spinner';
import { CreateSalesCompanyTask, GenerateSEOReportDataGrid, GetSalesCompanyDetailsBasic } from '../../../Services';
import GridMapDataGrid from '../../dataGrid/GridMapDataGrid';
import { toast } from 'react-toastify';
import './DataGrid.css';
import { useParams } from 'react-router-dom';
import { copyToClipboard, createGuid, slugify } from '../../../utils/Common';
import { GetSalesTask, RemoveSalesTask } from '../../../utils/StorageUtil';
import html2canvas from 'html2canvas';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import PageHeadline from '../../../components/pageHeadline/PageHeadline';

const DataGrid = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isGenerating, setIsGenerating ] = useState(false);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ keywords, setKeywords ] = useState('');
	const [ lat, setLat ] = useState('');
	const [ lng, setLng ] = useState('');
	const [ zoom, setZoom ] = useState(11);
	const [ searchType, setSearchType ] = useState('serp');
	const [ targetKeywordsArray, setTargetKeywordsArray ] = useState([]);
	const [ taskIds, setTaskIds ] = useState([]);
	const [ task, setTask ] = useState();
	const [ report, setReport ] = useState();
	const [ company, setCompany ] = useState();
	const { salesCompanyId } = useParams();
	const dataGridMapContainerRef = useRef();
	const [ { host, user }, dispatch ] = useStateValue();
	const [ showShareLinkCopiedMessage, setShowShareLinkCopiedMessage ] = useState(false);

	useEffect(
		() => {
			if (salesCompanyId) {
				getSalesCompanyDetails();

				let tempTask = GetSalesTask();

				if (tempTask) {
					setTask(tempTask);
				}
			}
		},
		[ salesCompanyId ]
	);

	const getSalesCompanyDetails = async () => {
		const response = await GetSalesCompanyDetailsBasic(salesCompanyId);

		if (response) {
			setCompany(response);
			setLat(response.lat);
			setLng(response.lng);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const handleGenerateReport = async (e) => {
		e.preventDefault();

		if (company && task) {
			if (user && user.credits <= 0) {
				toast.error("You don't have enough credits to run the process.");
				return;
			}

			if (keywords.trim() === '') {
				toast.error('Keywords is required field');
				return;
			}

			let keywordsCount = keywords.split(',').map((item) => item.trim()).filter((element) => element).length;
			let creditsToDeduct = keywordsCount * 3;

			if (window.confirm(`This process will reduce ${creditsToDeduct} credits from your account. Proceed?`)) {
				setIsGenerating(true);

				let data = {
					campaignId    : null,
					salesUserId   : user && user.isSalesUser ? user.id : null,
					googlePlaceId : company.googlePlaceId,
					keywords      : keywords.split(',').map((item) => item.trim()),
					gridPoints    : task.horizontalPoints,
					pointDistance : task.pointDistance.toString(),
					lat           : task.lat,
					lng           : task.lng,
					zoom          : parseInt(zoom),
					gridApiType   : searchType
				};

				const response = await GenerateSEOReportDataGrid(data);

				if (response.success) {
					setReport(response);

					let tempTask = task;
					tempTask.keywords = keywords;
					tempTask.zoom = zoom;
					tempTask.gridApiType = searchType;
					tempTask.report = response;
					setTask(tempTask);

					let tempTargetKeywordsArray = [];

					if (response.gridResponse && response.gridResponse.data) {
						for (let i = 0; i < response.gridResponse.data.length; i++) {
							tempTargetKeywordsArray.push({
								lat              : task.lat,
								lng              : task.lng,
								horizontalPoints : task.horizontalPoints,
								verticalPoints   : task.verticalPoints,
								keywordSlug      : slugify(response.gridResponse.data[i].keyword),
								...response.gridResponse.data[i]
							});
						}
					} else {
						tempTargetKeywordsArray.push({
							lat              : task.lat,
							lng              : task.lng,
							horizontalPoints : task.horizontalPoints,
							verticalPoints   : task.verticalPoints,
							keywordSlug      : '',
							...null
						});
					}

					setTargetKeywordsArray(tempTargetKeywordsArray);

					//deduct the credits
					if (user) {
						let tempUser = user;
						tempUser.credits -= creditsToDeduct;

						dispatch({
							type : actionTypes.SET_USER,
							user : tempUser
						});
					}
				} else {
					toast.error(response.message);
					console.error(response);
				}

				setIsGenerating(false);
			}
		}
	};

	const handleGenerateTasks = async () => {
		if (company && task) {
			setIsSaving(true);

			let keywordsArray = keywords.split(',');

			if (keywordsArray && keywordsArray.length > 0) {
				let tempTaskIds = [];
				let tempTargetKeywordsArray = targetKeywordsArray;

				for (const keyword of keywordsArray) {
					//take only appropriate keyword from grid response
					let tempReport = {};
					tempReport.googlePlaceId = task.report.googlePlaceId;
					tempReport.gridResponse = {};
					tempReport.gridResponse.lat = task.report.gridResponse.lat;
					tempReport.gridResponse.lng = task.report.gridResponse.lng;
					tempReport.gridResponse.horizontalPoints = task.report.gridResponse.horizontalPoints;
					tempReport.gridResponse.verticalPoints = task.report.gridResponse.verticalPoints;
					tempReport.gridResponse.data = task.report.gridResponse.data.filter(
						(k) => k.keyword.toLowerCase() === keyword.trim().toLowerCase()
					);

					let newTaskId = createGuid();

					let data = {
						id                        : newTaskId,
						salesCompanyId            : company.id,
						keyword                   : keyword,
						content                   : JSON.stringify(tempReport),
						lat                       : task.lat,
						lng                       : task.lng,
						gridPoints                : task.horizontalPoints,
						gridPointsDistanceInMiles : task.pointDistance
					};

					const response = await CreateSalesCompanyTask(data);

					if (response.success) {
						tempTaskIds.push(response.data.id);

						if (tempTargetKeywordsArray && tempTargetKeywordsArray.length > 0) {
							let tempTargetKeyword = tempTargetKeywordsArray.find(
								(k) => k.keyword.toLowerCase() === keyword.trim().toLowerCase()
							);

							if (tempTargetKeyword) {
								tempTargetKeyword.taskId = response.data.id;
							}
						}
					}
				}

				setTaskIds(tempTaskIds);
				setTargetKeywordsArray(tempTargetKeywordsArray);

				//delete local storage data
				RemoveSalesTask();
			}

			setIsSaving(false);
		}
	};

	const exportMapAsImage = (keywordSlug) => {
		if (zoom === '0') {
		} else {
			let $mapContainer = document.querySelector('#report-map-' + keywordSlug);

			if ($mapContainer) {
				dataGridMapContainerRef.current.classList.add('no-transition');

				html2canvas($mapContainer, { useCORS: true })
					.then((canvas) => {
						var imgSrc = canvas.toDataURL('image/png');

						var link = document.createElement('a');
						if (typeof link.download !== 'string') {
							window.open(imgSrc);
						} else {
							link.href = imgSrc;
							link.download = 'grid-' + keywordSlug + '.png';
							link.click();
							//accountForFirefox(clickLink, link);
						}
					})
					.finally(() => dataGridMapContainerRef.current.classList.remove('no-transition'));
			}
		}
	};

	const getReportShareLink = (taskId) => {
		var url = new URL(`${host}/sales/${salesCompanyId}/reports/${taskId}`);

		copyToClipboard(url.href);

		setShowShareLinkCopiedMessage(true);

		setTimeout(function() {
			setShowShareLinkCopiedMessage(false);
		}, 1000);
	};

	return (
		<Fragment>
			<div className="container-fluid sales-grid-size-container pb-0">
				<PageHeadline
					headline={`Data Grid ${company ? '- ' + company.name : ''}`}
					linkText="Tasks List"
					linkUrl={company ? `/sales/${company.id}/tasks` : '#'}
					className="pb-4"
				/>

				<div className="container text-center">
					<Fragment>
						{isLoading ? (
							<Spinner />
						) : (
							<form onSubmit={handleGenerateReport}>
								<div className="row px-2 mb-3">
									<div className="col-4">
										<div className="input-group">
											<label htmlFor="lat" className="form-label mt-2 me-4">
												Lat/Lng:
											</label>
											<input
												type="text"
												className="form-control rounded"
												id="lat"
												value={lat}
												onChange={(e) => setLat(e.target.value)}
											/>
											<input
												type="text"
												className="form-control rounded ms-2"
												id="lng"
												value={lng}
												onChange={(e) => setLng(e.target.value)}
											/>
											{company &&
											!company.stretAddress1 &&
											!company.city && (
												<a
													className="btn btn-link me-3"
													target="_blank"
													href={company.googleBusinessUrl ? company.googleBusinessUrl : '#'}
													title="Business Profile"
												>
													<i className="fa-brands fa-google" />
												</a>
											)}
										</div>
									</div>
									<div className="col-7">
										<div className="input-group">
											<label htmlFor="keywords" className="form-label mt-2 me-4">
												Keywords:
											</label>
											<input
												type="text"
												className="form-control"
												id="keywords"
												placeholder="Keywords (comma separated)"
												value={keywords}
												onChange={(e) => setKeywords(e.target.value)}
											/>
										</div>
									</div>
									{/* <div className="col-lg-1 col-12 px-1">
											<label htmlFor="zoom" className="form-label">
												Zoom:
											</label>
											<select
												className="form-select"
												value={zoom}
												onChange={(e) => setZoom(e.target.value)}
											>
												<option value="11">11</option>
												<option value="12">12</option>
												<option value="13">13</option>
												<option value="14">14</option>
												<option value="0">All Zooms</option>
											</select>
										</div>
										<div className="col-lg-2 col-12 px-1">
											<label htmlFor="searchType" className="form-label">
												Api:
											</label>
											<select
												className="form-select"
												value={searchType}
												onChange={(e) => setSearchType(e.target.value)}
											>
												<option value="serp">Serp Api</option>
												<option value="dseo">DataForSeo Api</option>
											</select>
										</div> */}
									<div className="col-1 px-0">
										<button type="submit" className="btn btn-primary w-100">
											<span>Generate</span>
										</button>
									</div>
								</div>
							</form>
						)}
					</Fragment>
				</div>
			</div>

			<div className="container text-center">
				<Fragment>
					<div className="data-grid-map-container" ref={dataGridMapContainerRef}>
						<Fragment>
							{targetKeywordsArray &&
							company &&
							task && (
								<Fragment>
									<ul className="nav nav-tabs" id="tabKeywordGrids" role="tablist">
										<Fragment>
											{targetKeywordsArray.map((targetKeyword, index) => (
												<li className="nav-item" role="presentation" key={index}>
													<button
														className={`nav-link ${index === 0 ? 'active' : ''}`}
														id={`${targetKeyword.keywordSlug}-tab`}
														data-bs-toggle="tab"
														data-bs-target={`#${targetKeyword.keywordSlug}-tab-pane`}
														type="button"
														role="tab"
														aria-controls={`${targetKeyword.keywordSlug}-tab-pane`}
														aria-selected="true"
													>
														{targetKeyword.keyword}
													</button>
												</li>
											))}
										</Fragment>
									</ul>
									<div
										className="tab-content grid-keywords-content border"
										id="tabKeywordGridsContent"
									>
										{targetKeywordsArray.map((targetKeyword, index) => (
											<Fragment key={index}>
												<div
													key={index}
													className={`tab-pane fade ${index === 0 ? 'show active' : ''}`}
													id={`${targetKeyword.keywordSlug}-tab-pane`}
													role="tabpanel"
													aria-labelledby={`${targetKeyword.keywordSlug}-tab`}
													tabIndex={index}
												>
													{targetKeyword && (
														<GridMapDataGrid
															key={`map-${index}`}
															mapIdValue={`report-map-${targetKeyword.keywordSlug}`}
															gridData={targetKeyword}
															lat={task.lat}
															lng={task.lng}
															googlePlaceId={company.googlePlaceId}
															numberOfPoints={task.horizontalPoints}
															zoom={zoom}
														/>
													)}

													{report &&
													targetKeyword.taskId && (
														<div className="text-start my-3">
															<Fragment>
																<button
																	type="button"
																	className="btn btn-primary"
																	onClick={() =>
																		getReportShareLink(targetKeyword.taskId)}
																>
																	<span>
																		{showShareLinkCopiedMessage ? (
																			'Copied!'
																		) : (
																			'Get Share Link'
																		)}
																	</span>
																</button>
																<button
																	type="button"
																	className="btn btn-primary ms-2"
																	onClick={() =>
																		exportMapAsImage(targetKeyword.keywordSlug)}
																>
																	<span>Export As Image</span>
																</button>
															</Fragment>
														</div>
													)}
												</div>
											</Fragment>
										))}
									</div>
								</Fragment>
							)}
							{isGenerating && (
								<div className="loading-map">
									<Spinner />
								</div>
							)}
						</Fragment>
					</div>

					{report && (
						<div className="text-start my-3">
							{(!taskIds || taskIds.length === 0) && (
								<button type="button" className="btn btn-primary" onClick={handleGenerateTasks}>
									{isSaving ? (
										<Fragment>
											<span
												className="spinner-border m-0 me-2"
												role="status"
												aria-hidden="true"
											/>
											<span>Saving</span>
										</Fragment>
									) : (
										<span>Save Report</span>
									)}
								</button>
							)}
						</div>
					)}
				</Fragment>
			</div>
		</Fragment>
	);
};

export default DataGrid;
