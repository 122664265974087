import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './RequirementsApprovalRequestPage.css';
import { Fragment } from 'react';
import { ApproveRequirements, CreateRequirementsMessage, GetRequirementDetails } from '../../../Services';
import { toast } from 'react-toastify';

const RequirementsApprovalRequestPage = () => {
	//const [ isLoading, setIsLoading ] = useState(true);
	const [ requirementsData, setRequirementsData ] = useState({});
	const [ videoEmbedLink, setVideoEmbedLink ] = useState('');
	const [ changeRequestComments, setChangeRequestComments ] = useState();
	//const [ clickEventAdded, setClickEventAdded ] = useState(false);
	const { requirementsId, cl } = useParams();
	const [ isSubmitting, setIsSubmitting ] = useState(false);
	const [ isApproving, setIsApproving ] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		document.body.classList.add('gray-background');

		getOrderRequirementsDetails();
	}, []);

	const getOrderRequirementsDetails = async () => {
		if (requirementsId) {
			const response = await GetRequirementDetails(requirementsId);

			if (response && response.id) {
				let tempRequirementsData = response;

				if (cl) {
					// let data = {
					// 	campaignReportHistoryId : tempRequirementsData.id,
					// 	clickedLink             : tempRequirementsData.shareUrl,
					// 	type                    : 'Click'
					// };
					// await AddReportHistoryClickEvent(tempRequirementsData.id, data);
					// setClickEventAdded(true);
				}

				if (tempRequirementsData.videoUrl) {
					let tempVideoEmbedLink = tempRequirementsData.videoUrl.replace('share', 'embed');
					tempVideoEmbedLink += (tempVideoEmbedLink.indexOf('?') === -1 ? '?' : '&') + `hideEmbedTopBar=true`;

					setVideoEmbedLink(tempVideoEmbedLink);
				}

				//setReportDate(moment(tempRequirementsData.dateCreated).format('dddd, MMMM Do yyyy'));

				setRequirementsData(tempRequirementsData);
			}
		}

		//setIsLoading(false);
	};

	const submitComments = async () => {
		setIsSubmitting(true);

		if (!changeRequestComments || changeRequestComments.trim() === '') {
			toast.error('Please enter your change request comments');
			setIsSubmitting(false);
			return;
		}

		let data = {
			requirementId : requirementsId,
			type          : 'ApprovalComments',
			direction     : 'Inbound',
			subject       : 'Customer Approval Comments',
			message       : changeRequestComments,
			status        : 'Sent',
			category      : 'Email'
		};

		let response = await CreateRequirementsMessage(requirementsId, data);

		if (response.success) {
			navigate('/map-boost-approval-change-thank-you');
		}

		setIsSubmitting(false);
	};

	const approveRequirements = async () => {
		setIsSubmitting(true);

		const response = await ApproveRequirements(requirementsId);

		if (response.success) {
			navigate('/map-boost-approval-thank-you');
		}

		setIsSubmitting(false);
	};

	return (
		<Fragment>
			<div className="container-fluid requirements-approval-container">
				<div className="container">
					<div className="requirements-approval-container-inner">
						<div className="row">
							<div className="col-12 title-wrapper">
								<h2 className="title text-center">Map Boost Setup Approval Request</h2>
							</div>

							<div className="col-12 mb-2">
								<p className="text-center">
									<strong>Watch This Short Video</strong>
								</p>
							</div>

							<div className="col-12">
								<div className="video-wrapper">
									<iframe
										src="https://www.loom.com/embed/aee91161e9b04db8ad1341e21039a30c?sid=880b9b03-c1fe-49cb-b1ec-8ac2b35bc5f7"
										// frameborder="0"
										webkitallowfullscreen="true"
										mozallowfullscreen="true"
										allowFullScreen
									/>
								</div>
							</div>

							<div className="col-12 my-4">
								<hr />
							</div>

							<div className="col-12 title-wrapper">
								<h2 className="title text-center">Campaign Details</h2>
							</div>

							<div className="col-12 mb-4">
								<p>
									Please verify the information below and the{' '}
									<strong>click the "Approve" button at the bottom of this page</strong> and we will
									get started on building this campaign.
								</p>
							</div>

							<div className="col-12 mb-4">
								<p className="mb-1">
									<strong>Business Name:</strong>
								</p>
								<p>{requirementsData.company}</p>
							</div>

							<div className="col-12 mb-4">
								<p className="mb-1">
									<strong>Business Address:</strong>
								</p>
								<p>{requirementsData.address}</p>
							</div>

							<div className="col-12 mb-4">
								<p className="mb-1">
									<strong>Business Profile:</strong>
								</p>
								<p>
									<a href={requirementsData.googleBusinessUrl} target="_blank">
										{requirementsData.googleBusinessUrl}
									</a>
								</p>
							</div>

							<div className="col-12 mb-4">
								<p className="mb-2">
									<strong>Campaign Services:</strong>
								</p>
								{requirementsData.services && (
									<Fragment>
										<pre className="mb-2 ms-4">{requirementsData.services}</pre>
									</Fragment>
								)}
							</div>

							<div className="col-12 mb-2">
								<p className="mb-2">
									<strong>Campaign Keywords:</strong>
								</p>
								<p>
									Below are the keywords that we have verified that get the most search volume and
									display business profiles on the Google search results.
								</p>
								{requirementsData.keywords && (
									<Fragment>
										<pre className="mb-2 ms-4">{requirementsData.keywords}</pre>
									</Fragment>
								)}
							</div>

							{requirementsData.status === 'Approval' && (
								<Fragment>
									<div className="col-12 mb-3">
										<hr />
									</div>

									<div className="need-changes-notice-wrapper">
										<div className="col-12 mb-3 need-changes-notice">
											<p className="mb-2">
												<strong>Need To Make Changes?</strong>
											</p>
											<p className="mb-3">
												Enter your change request comments in the box below and we will resend
												this approval request with the updated details for you to review and
												accept.
											</p>
											<textarea
												className="form-control"
												rows={5}
												name="changeRequestComments"
												onChange={(e) => setChangeRequestComments(e.target.value)}
												disabled={isSubmitting}
											/>

											<button
												className="btn btn-primary mt-2"
												onClick={submitComments}
												disabled={isSubmitting}
											>
												{isSubmitting ? 'Submitting' : 'Submit'}
											</button>
										</div>
									</div>
								</Fragment>
							)}

							<div className="col-12 mb-4">
								<hr />
							</div>

							<div className="col-12 title-wrapper">
								<h2 className="title text-center">Approve & Get Launched!</h2>
							</div>

							<div className="col-12">
								<p className="mb-1">
									If the <strong>business profile information and keywords above are ok</strong>, then{' '}
									<u>click the Approve button below</u> and your campaign will be launched within 3-5
									business days.
								</p>
								<p className="my-3">
									<strong>Here's What Happens Next:</strong>
								</p>
								<p className="mb-3">
									You will receive an email & SMS notification with a link to your campaign launch
									report that will show where your Google Business Profile currently ranks in your
									service area for each of the keywords above.
								</p>
								<p className="mb-3">
									We will also provide a link to your "local directory site" that will have 500+ pages
									optimized for the services you offer and locations within your service area. (see
									the explainer video)
								</p>
								<p className="mb-3">
									Once the campaign is launched Google will begin to index and rank all of the
									directory site pages we created and you will receive the 2nd report in 30 days
									showing the initial results.
								</p>
								<p className="mb-3">
									Our expectation is <strong>after 30 days</strong> you will see many (typically over
									100+) directory site pages for your business listed #1 at the top of Google orgnaic
									search results.
								</p>
								<p className="mb-3">
									<u>Every 30 days for the next 11 months</u> we will send a campaign update report
									tracking the progress and rank improvements of your Google Business Profile.
								</p>
							</div>
						</div>
					</div>
				</div>
				{requirementsData.status === 'Approval' && (
					<div className="container approve-button-container">
						<div className="approve-button-container-inner">
							<button className="btn report-url-link" onClick={approveRequirements}>
								Yes, I Approve
							</button>
						</div>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default RequirementsApprovalRequestPage;
