import { Fragment, useEffect, useRef, useState } from 'react';
import './UsersList.css';
import { AppLoginAs, GetLoginAsToken, RegisterUser, UpdateUser } from '../../../../Services';
import { actionTypes } from '../../../../reducer';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { toast } from 'react-toastify';
import { useStateValue } from '../../../../StateProvider';
import { setToken } from '../../../../utils/StorageUtil';

const UsersList = ({ allUsers, onSubmit }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ users, setUsers ] = useState([]);
	const [ formData, setFormData ] = useState({});
	const [ showDialog, setShowDialog ] = useState(false);
	const [ editedUserId, setEditedUserId ] = useState(null);
	const [ showAdminUsers, setShowAdminUsers ] = useState(false);
	const [ showSalesUsers, setShowSalesUsers ] = useState(false);
	const searchListInputRef = useRef();

	useEffect(
		() => {
			if (allUsers && allUsers.length > 0) {
				let tempAdminUsers = [];
				let tempSalesUsers = [];

				if (showAdminUsers) {
					tempAdminUsers = allUsers.filter((x) => x.isAdmin === true);
				}

				if (showSalesUsers) {
					tempSalesUsers = allUsers.filter((x) => x.isSalesUser === true);
				}

				if (tempAdminUsers.length || tempSalesUsers.length) {
					setUsers([ ...new Set([ ...tempAdminUsers, ...tempSalesUsers ]) ]);
				} else {
					setUsers(allUsers);
				}
			}
		},
		[ allUsers, showAdminUsers, showSalesUsers ]
	);

	const handleChange = async (e) => {
		let type = e.target.type;
		let value = e.target.value;

		if (type === 'checkbox') {
			value = e.target.checked;
		}

		setFormData({
			...formData,
			[e.target.name]: value
		});
	};

	const handleSubmitUser = async (e) => {
		e.preventDefault();

		let userData = formData;
		let userResponse;

		let isValid = document.querySelector('form').reportValidity();

		if (!isValid) {
			return;
		}

		let isSuccess = false;

		if (editedUserId === null) {
			userData.status = 'Active';

			userResponse = await RegisterUser(userData);
		} else {
			userResponse = await UpdateUser(editedUserId, userData);
		}

		if (userResponse) {
			if (editedUserId) {
				isSuccess = true;
			} else {
				if (userResponse.id) {
					isSuccess = true;
				}
			}
		}
		if (isSuccess) {
			toast.success('User' + (editedUserId === null ? ' created ' : ' updated ') + 'successfully');

			setShowDialog(false);

			if (onSubmit) {
				onSubmit();
			}
		} else {
			if (userResponse.Message && userResponse.Message.response && userResponse.Message.response.data) {
				if (Array.isArray(userResponse.Message.response.data)) {
					toast.error(userResponse.Message.response.data.join('\n'));
				} else {
					toast.error('Something went wrong. Please try again');
					//toast.error(userResponse.Message.response.data);
				}
			}
		}
	};

	const handleToggleDialog = async (e, id) => {
		e.preventDefault();

		setShowDialog(!showDialog);

		if (!showDialog) {
			if (id !== undefined) {
				//edit
				setEditedUserId(id);
				let currentUser = users.find((x) => x.id === id);

				if (currentUser) {
					setFormData(currentUser);
				}
			} else {
				//new
				setEditedUserId(null);
				setFormData({});
			}
		} else {
			setEditedUserId(null);
			setFormData({});
		}
	};

	const loginAs = async (e, userId) => {
		e.preventDefault();

		setIsLoading(true);

		let data = { id: userId };

		//get new user token
		const loginAsTokenResponse = await GetLoginAsToken(data);

		if (loginAsTokenResponse) {
			setToken(loginAsTokenResponse);

			//login new user
			const loginAsResponse = await AppLoginAs(data);

			if (loginAsResponse) {
				const tempLoggedInAsUser = loginAsResponse;

				dispatch({
					type : actionTypes.SET_USER,
					user : tempLoggedInAsUser
				});

				if (tempLoggedInAsUser.isAdmin) {
					window.location.href = '/campaigns/list';
				} else if (tempLoggedInAsUser.isSalesUser) {
					window.location.href = '/sales';
				} else if (!tempLoggedInAsUser.isAdmin && !tempLoggedInAsUser.isSalesUser) {
					window.location.href = '/campaigns/list';
				}
			}
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			<Fragment>
				<ul className="nav nav-tabs" id="tabUsers" role="tablist">
					<li className="nav-item" role="presentation">
						<button
							className="nav-link active"
							id="active-users-tab"
							data-bs-toggle="tab"
							data-bs-target="#active-users-tab-pane"
							type="button"
							role="tab"
							aria-controls="active-users-tab-pane"
							aria-selected="true"
						>
							Active
						</button>
					</li>
				</ul>
				<div className="tab-content border" id="tabUsersContent">
					<div
						className="tab-pane fade position-relative show active"
						id="active-users-tab-pane"
						role="tabpanel"
						aria-labelledby="active-users-tab"
						tabIndex="0"
					>
						<div className="new-user-wrapper">
							<button className="btn btn-primary" onClick={(e) => handleToggleDialog(e, null)}>
								Add New User
							</button>
						</div>
						<div className="table-wrapper">
							<table className="users-list items-list table table-responsive">
								<thead>
									<tr>
										<th className="number" scope="col">
											#
										</th>
										<th className="name" scope="col">
											Name
										</th>
										<th className="email" scope="col">
											Email
										</th>
										<th className="count" scope="col">
											Admin
											<input
												type="checkbox"
												className="form-check-input ms-2"
												name="showAdminUsers"
												value={showAdminUsers}
												onChange={(e) => setShowAdminUsers(e.target.checked)}
											/>
										</th>
										<th className="count" scope="col">
											Sales
											<input
												type="checkbox"
												className="form-check-input ms-2"
												name="showSalesUsers"
												value={showSalesUsers}
												onChange={(e) => setShowSalesUsers(e.target.checked)}
											/>
										</th>
										<th className="count" scope="col">
											Credits
										</th>
										<th className="user-action" scope="col" />
									</tr>
								</thead>
								<tbody>
									{users &&
										users.length > 0 &&
										users.map((user, index) => (
											<tr key={user.id}>
												<th className="number" scope="row">
													{index + 1}
												</th>
												<td className="name text-truncate">{`${user.firstName} ${user.lastName}`}</td>
												<td className="email">{user.email}</td>
												<td className="count">{user.isAdmin ? 'Yes' : ''}</td>
												<td className="count">{user.isSalesUser ? 'Yes' : ''}</td>
												<td className="count">{user.credits}</td>
												<td className="user-action text-end">
													<button
														className="btn btn-link py-0"
														onClick={(e) => handleToggleDialog(e, user.id)}
														title="Edit User Details"
													>
														<i className="fa-solid fa-pen" />
													</button>
													<button
														className="btn btn-link py-0"
														onClick={(e) => loginAs(e, user.id)}
														title="Login As"
													>
														<i className="fa-solid fa-user-gear" />
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</Fragment>

			{showDialog && (
				<ModalDialog position="right-sidebar">
					<ModalDialogContent align="start">
						<form className="row">
							<div className="col-12 mb-3">
								<label htmlFor="firstName" className="form-label">
									First Name:
								</label>
								<input
									type="text"
									className="form-control"
									name="firstName"
									value={formData.firstName || ''}
									onChange={handleChange}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="lastName" className="form-label">
									Last Name:
								</label>
								<input
									type="text"
									className="form-control"
									name="lastName"
									value={formData.lastName || ''}
									onChange={handleChange}
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="email" className="form-label">
									Email:
								</label>
								<input
									type="email"
									className="form-control"
									name="email"
									value={formData.email || ''}
									onChange={handleChange}
									required
								/>
							</div>
							<div className="col-12 mb-3">
								<label htmlFor="password" className="form-label">
									Password:
								</label>
								<input
									type="password"
									className="form-control"
									name="password"
									value={formData.password || ''}
									onChange={handleChange}
								/>
							</div>
							{/* <div className="col-12 mb-3">
								<label htmlFor="phone" className="form-label">
									Phone:
								</label>
								<input
									type="text"
									className="form-control"
									name="phone"
									value={formData.phone || ''}
									onChange={handleChange}
								/>
							</div> */}

							{editedUserId !== null && (
								<div className="col-12 mb-3">
									<label htmlFor="credits" className="form-label">
										Credits:
									</label>
									<input
										type="number"
										className="form-control"
										name="credits"
										value={formData.credits || '0'}
										onChange={handleChange}
									/>
								</div>
							)}

							<div className="col-12 mb-3 mt-2">
								<label htmlFor="isAdmin" className="form-label">
									Is Admin:
								</label>
								<input
									type="checkbox"
									className="form-check-input ms-3"
									name="isAdmin"
									value={formData.isAdmin || false}
									defaultChecked={formData.isAdmin}
									onChange={handleChange}
								/>
							</div>
							<div className="col-12 mb-0">
								<label htmlFor="isSalesUser" className="form-label">
									Is Sales Rep:
								</label>
								<input
									type="checkbox"
									className="form-check-input ms-3"
									name="isSalesUser"
									value={formData.isSalesUser || false}
									defaultChecked={formData.isSalesUser}
									onChange={handleChange}
								/>
							</div>
						</form>
					</ModalDialogContent>
					<ModalDialogButtons>
						<button className="btn btn-primary" type="button" onClick={handleSubmitUser}>
							Save
						</button>
						<button
							className="btn btn-outline-primary ms-2"
							type="button"
							onClick={(e) => handleToggleDialog(e, null)}
						>
							Cancel
						</button>
					</ModalDialogButtons>
				</ModalDialog>
			)}
		</Fragment>
	);
};

export default UsersList;
