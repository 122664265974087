import { Fragment } from 'react';
import './TableFooter.css';

const TableFooter = ({ list, colspan, showRefreshButton = null, onListRefresh = null }) => {
	return (
		<Fragment>
			<tfoot className="list-footer">
				<tr>
					<th colSpan={colspan}>
						<div className="items-list-count">
							<span>
								{!list || list.length === 0 ? (
									'0 items'
								) : list.length === 1 ? (
									'1 item'
								) : (
									`${list.length} items`
								)}
							</span>

							{showRefreshButton && (
								<button className="btn btn-link btn-refresh-list" onClick={onListRefresh}>
									Refresh
								</button>
							)}
						</div>
					</th>
				</tr>
			</tfoot>
		</Fragment>
	);
};

export default TableFooter;
